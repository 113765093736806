import React, { useEffect, useState } from "react";

import { getVideos } from "../../services/API";

import { VideoType } from "../../types/componentTypes";

import "../../styles/main.scss";
import "./Videos.scss";

const Videos = () => {

  const [videos, setVideos] = useState<VideoType[]>([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videos = await getVideos();
        setVideos(videos);
      } catch (error) {
        console.error(error);
      }
    }
    fetchVideos();
  }, []);

  return (
    <div id='videos'>
      {videos.length > 0 && 
      <div className="video-container">
        <iframe
          src={`https://www.youtube.com/embed/${videos[0].url}`}
          title={videos[0].title}
          allow=' clipboard-write; encrypted-media; web-share'
          allowFullScreen
        ></iframe>
      </div>}
    </div>
  );
}

export default Videos;