import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faMixcloud, faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "../../styles/main.scss";
import "./Contact.scss";

const Contact: React.FC = () => {

  const links = [
    { name: "Instagram", href: "https://www.instagram.com/object___object/", icon: faInstagram },
    { name: "Mixcloud", href: "https://www.mixcloud.com/objectobjectobject/", icon: faMixcloud },
    { name: "Soundcloud", href: "https://www.soundcloud.com/objectobjectobject/", icon: faSoundcloud },
    { name: "Email", href: "mailto:alexryanjones@gmx.com", icon: faEnvelope },
  ];

  const recalculateDivPadding = () => {
    const container = document.querySelector<HTMLElement>('#contact');

    if (container) {

      const conatinerStyle: CSSStyleDeclaration = window.getComputedStyle(container);
      const children = Array.from(container.querySelectorAll<HTMLElement>('.contact-icon'));

      if (children) {

        const containerWidth = container.clientWidth - parseFloat(conatinerStyle.paddingLeft);
        const containerHeight = container.clientHeight;

        const childStyle: CSSStyleDeclaration = window.getComputedStyle(children[0]);
        const childMargin = parseFloat(childStyle.marginLeft) * 2;
        const childWidth = children[0].offsetWidth + childMargin;

        const elementsPerRow = Math.floor(containerWidth / childWidth);
        const rowCount = Math.ceil(children.length / elementsPerRow);

        const padding = `${(containerHeight - ((children[0].offsetHeight + (childMargin * 2)) * rowCount))/ 2}px`;
        container.style.paddingTop = padding;
        container.style.paddingBottom = padding;
      }
    }
  }

  useEffect(() => {
    recalculateDivPadding();
    window.addEventListener('resize', recalculateDivPadding);
    return () => {
      window.removeEventListener('resize', recalculateDivPadding);
    };
  }, []);

  return (
    <div id="contact">
      {links.map(link => {
        return (
          <a className='contact-icon' key={link.name} href={link.href} target='_blank' rel="noreferrer">
            <FontAwesomeIcon key={link.name} icon={link.icon} />
          </a>
        );
      })}
    </div>
  );
}

export default Contact;