import React from 'react';
import ObjRepeat from './ObjRepeat/ObjRepeat';

import './Background.scss';

const Background: React.FC = () => {
  return (
    <div className='background-container'>
      <ObjRepeat />
    </div>
  );
}

export default Background;