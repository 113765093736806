import React, { useEffect, useRef, useState } from "react";

import "../../styles/main.scss";
import "./About.scss";
import { ComponentProps } from "../../types/componentTypes";

const About: React.FC<ComponentProps> = ({ AppContainer }) => {

  const AboutContainer = useRef<HTMLDivElement | null>(null);

  // Set About divs
  const aboutParagraphs = [
    '[object Object]',
    'Not confined by genres and fascinated by the full spectrum of electronic music. Exploring relationships between texture rhythm, and blending exciting and introspective sounds alike.',
    'An eclectic taste and collection developed through years of crate digging and label sourcing from time based in Barcelona, travelling throughout Europe and influenced by roots in the UK, allowing to harmonise the obscure with the familiar to craft an immersive and dynamic musical journey.',
  ];

  const aboutElementsRefs = useRef(
    aboutParagraphs.map((text) => ({
      ref: React.createRef<HTMLDivElement>(),
      text,
    }))
  );

   const [activeCursorRef, setActiveCursorRef] = useState<HTMLElement | null>(null); 

  useEffect(() => {
    const revealText = () => {
      let accumulatedDelay = 0;

      aboutElementsRefs.current.forEach((paragraph) => {
        if (paragraph.ref.current) {
          const characters: NodeListOf<HTMLParagraphElement> =
            paragraph.ref.current.querySelectorAll('p');
          
          let totalParagraphDelay = 0;

          characters.forEach(
            (char: HTMLParagraphElement, charIndex: number) => {
              const charDelay = Math.random() * 100;

              
              setTimeout(() => {
                requestAnimationFrame(() => {
                  if (activeCursorRef) {
                    char.insertAdjacentElement('afterend', activeCursorRef);
                    if (charIndex < characters.length - 1) {
                      activeCursorRef.style.animation = 'none';
                      void activeCursorRef.offsetHeight;
                      activeCursorRef.style.animation = '';
                    }
                    char.style.visibility = 'visible';
                  }
                });
              }, accumulatedDelay + totalParagraphDelay);

              totalParagraphDelay += charDelay;
            }
          );

          accumulatedDelay += totalParagraphDelay;
        }
      });
    };

    const handleTypeWriterAnimation = () => {
      if (AppContainer.current && AboutContainer.current) {
        const viewportHeight = window.innerHeight;
  
        const currentScrollPosition = AppContainer.current!.scrollTop;
        const scrollPointToReach = viewportHeight * 3;
        const containerInView = currentScrollPosition >= scrollPointToReach;
  
        if (containerInView) {
          revealText();
          AppContainer.current!.removeEventListener(
            'scroll',
            handleTypeWriterAnimation
          );
        }
      }
    }

    AppContainer.current!.addEventListener('scroll', handleTypeWriterAnimation);

  }, [AppContainer, AboutContainer, activeCursorRef]);

  return (
    <div id='about' ref={AboutContainer}>

      {aboutElementsRefs.current.map((element, index) => (
        <span key={index} ref={element.ref}>
          {Array.from(element.text).map((char, index) => (
            <p key={index}>{char}</p>
          ))}
        </span>
      ))}

      <span className='cursor' ref={(el) => {
          if (el) setActiveCursorRef(el);
        }}
      ></span>

    </div>
  );
}

export default About;