import React, { useEffect, useState } from "react";


import { MixType } from "../../types/componentTypes";

import "./Mix.scss";

const MixPlayer: React.FC<MixType> = ({ title, url, cover, date, handleSelectMix }) => {
  
  useEffect(() => {
    window.addEventListener('click', resetSelectedMix);

    return () => {
      window.removeEventListener('click', resetSelectedMix);
    };
  }, [handleSelectMix]);

  const resetSelectedMix = (event: MouseEvent) => {
    if (
      event.target &&
      ((event.target as Element).closest('.mix-container') ||
        (event.target as Element).closest('#mix-player-info'))
    ) {
      preventClickBubbling(event);
    } else {
      handleSelectMix!(null);
    }
  };

  const preventClickBubbling = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  return (
    <div id='mix-player-container'>
      <div className='mix-container'>
        <img src={cover} alt={title} className='mix-cover' />
      </div>
      <div id='mix-player-info'>
        <div className='mix-info'>
          <p>{title}</p>
          <p>{date as string}</p>
        </div>
        <iframe className='mix-player' title={title} src={url}></iframe>
      </div>
    </div>
  );
}

export default MixPlayer;