import React, { useRef } from "react";

import "../../styles/main.scss";
import "./Photos.scss";
import DJ1 from "../../assets/images/DJ1.jpg"
import DJ2 from "../../assets/images/DJ2.gif";
import DJ3 from '../../assets/images/DJ3.jpg';


const Photos: React.FC = () => {
  const PhotosContainer = useRef<HTMLDivElement | null>(null);

  const photoUrlsArray = [
    DJ3,
    DJ2,
    DJ1,
    // "https://thumbs.dreamstime.com/z/cool-dj-boy-expressive-little-headphones-mixing-up-some-party-music-32192954.jpg?ct=jpeg",
  ];

  const photoElementsRefs = useRef(
    photoUrlsArray.map((url) => ({
      ref: React.createRef<HTMLImageElement>(),
      url,
    }))
  );

  return (
    <div id="photos" ref={PhotosContainer}>
      {photoElementsRefs.current.map((element, index) => (
        <img alt="Some DJ" className={'animate photo-' + (index + 1).toString()} key={index} ref={element.ref} src={element.url} />
      ))}
    </div>
  );
}

export default Photos;