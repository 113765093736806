import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faRecordVinyl,
  faVideoCamera,
  faCamera,
  faInfo,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

import { NavIconProps } from "../../types/componentTypes";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import "./Nav.scss";

const iconMap: Record<string, IconDefinition> = {
  mixes: faRecordVinyl,
  videos: faVideoCamera,
  about: faInfo,
  photos: faCamera,
  contact: faAt,
};

const NavIcon: React.FC<NavIconProps> = ({ title, onClick }) => {
  const icon = iconMap[title.toLowerCase()];

  return (
    <div className="nav-icon" onClick={onClick}>
      <FontAwesomeIcon className="nav-icon-symbol" icon={icon} />
    </div>
  );

}

export default NavIcon;