import React, { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import vinyl from "../../assets/images/vinyl.png";

import { MixType, MixProps } from "../../types/componentTypes";

import "./Mix.scss";

const Mix: React.FC<MixProps> = ({ index, title, url, cover, date, readyToAnimate, handleSelectMix }) => {

  const [animationDone, setAnimationDone] = useState<boolean>(false);
  const [initialTranslateY, setInitialTranslateY] = useState<number | null>(null);
  const [initialRotateX, setInitialRotateX] = useState<number | null>(null);

  const MixRef = useRef<HTMLDivElement>(null);

  const mix: MixType = {
    title,
    url,
    cover,
    date,
    handleSelectMix,
  };

  const setTransformationValues = (transformations: string[]) => {
    const translateYValue = parseFloat(transformations[0].match(/([a-zA-Z]+)\(([^)]+)\)/)![2]);
    const rotateXValue = parseFloat(transformations[1].match(/([a-zA-Z]+)\(([^)]+)\)/)![2]);
    setInitialTranslateY(translateYValue);
    setInitialRotateX(rotateXValue);
  }

  useEffect(() => {
    setAnimationDone(readyToAnimate);
  }, [readyToAnimate]);

  useEffect(() => {
    if (animationDone && MixRef.current) {
      const currentMixRef = MixRef.current;
      
      requestAnimationFrame(() => {
        currentMixRef.style.boxShadow = '0px -10px 20px rgb(0, 0, 0, 1)';
        const transformations = currentMixRef.style.transform.split(' ');
        if (transformations.length && transformations[0].length > 0) {
          setTransformationValues(transformations);
        }
      });

    }
  }, [animationDone]);

  useEffect(() => {

    if (animationDone) {
      const AppContainer = document.getElementById('App')!;

      const viewportHeight = window.innerHeight;
      const scrollSectionHeight = Math.floor((viewportHeight / 5));
      
      const shiftElementY = () => {
        const currentScrollPosition = AppContainer.scrollTop;
        const inFocus = Math.floor(currentScrollPosition / scrollSectionHeight) === index;
        const currentSection = Math.floor(currentScrollPosition / scrollSectionHeight);
        
        if (MixRef.current && (initialTranslateY || (index === 3 && initialTranslateY === 0)) && (initialRotateX || (index === 0 && initialRotateX === 0))) {

          if (inFocus) {
            MixRef.current.style.transform = `translateY(${initialTranslateY - 3}rem) rotateX(${initialRotateX - 5}deg)`;
            MixRef.current.classList.add('in-focus');
          } else {
            MixRef.current.classList.remove('in-focus');
          }

          if (currentSection < index) {
            MixRef.current.style.transform = `translateY(${initialTranslateY + 3}rem) rotateX(${initialRotateX - 10}deg)`;
          }

          if (currentSection >= index) {
            MixRef.current.style.transform = `translateY(${initialTranslateY - 3}rem) rotateX(${initialRotateX}deg)`
          }
        }
      }

      AppContainer.addEventListener('scroll', shiftElementY);
  
      return () => {
        AppContainer.removeEventListener('scroll', shiftElementY);
      };
    }
  }, [MixRef, initialTranslateY, initialRotateX, animationDone, index])

  return (
    <div className='mix-container' ref={MixRef} onClick={() => handleSelectMix!(mix)}>
      <img src={cover} alt={title} className='mix-cover' />
      <img src={vinyl} alt={`${title} vinyl`} className='vinyl' />
      <FontAwesomeIcon icon={faPlay} className='mix-play-icon' />
    </div>
  );
}

export default Mix;