import React, { useEffect, useState, useRef } from 'react';

import { ObjRepeatDimensions } from '../../../types/componentTypes';

import './ObjRepeat.scss';

const ObjRepeat: React.FC = () => {

  const word = 'object';
  const [wordDimensions, setWordDimensions] = useState<ObjRepeatDimensions | null>(null);
  const [rowArray, setRowArray] = useState<string[]>([]);
  const [rowsArray, setRowsArray] = useState<string[][]>([]);
  const timeoutIds = useRef<ReturnType<typeof setTimeout>[]>([]);


  useEffect(() => {
    setWords();
  }, [wordDimensions]);

  useEffect(() => {
    handleHighlighting();
  }, [rowsArray]); 

  const setWords = () => {
    resetHighlighting();
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    if (wordDimensions) {
      const wordsPerRow = Math.floor(pageWidth / wordDimensions.width) + 2;
      const rowsPerPage = Math.floor(pageHeight / wordDimensions.height) + 5;

      const rowArray = new Array(wordsPerRow).fill(word);
      setRowArray(rowArray);
      setRowsArray(new Array(rowsPerPage).fill(rowArray));
    } else {
      handleDimensions(word);
    }
  }

  const handleDimensions = (text: string) => {
    const temporaryDiv = document.createElement('div');
    temporaryDiv.style.display = 'inline-block';
    temporaryDiv.style.visibility = 'hidden';
    temporaryDiv.classList.add('object-repeat-line');
    temporaryDiv.innerText = text;
    document.body.appendChild(temporaryDiv);
    const rect = temporaryDiv.getBoundingClientRect();
    const width = temporaryDiv.offsetWidth * 1.5;
    const height = temporaryDiv.offsetHeight * 1.5;
    document.body.removeChild(temporaryDiv);
    setWordDimensions({width, height})
  };

  const handleHighlighting = () => {
    if (rowsArray.length > 0) {
      const objectSpans = document.getElementsByClassName(
        'object-repeat-word'
      ) as HTMLCollectionOf<HTMLElement>;
      const objectSpansArray = Array.from(objectSpans);

      const applyHighlightStyle = (element: HTMLElement) => {
        element.style.color = 'white';
        element.style.opacity = '1';
      };

      const indexesToHighlight = [
        rowArray.length - 1,
        objectSpansArray.length - rowArray.length,
      ];

      indexesToHighlight.forEach((index) => {
        if (index >= 0 && index < objectSpansArray.length) {
          applyHighlightStyle(objectSpansArray[index]);
        }
      });

      const delayIncrement = 100;

      const toggleColorClass = (element: HTMLElement, delay: number) => {
        const timeoutId = setTimeout(() => {
          element.classList.add('color-me');
          const removalTimeoutId = setTimeout(() => {
            element.classList.remove('color-me');
          }, delayIncrement);
          timeoutIds.current.push(removalTimeoutId);
        }, delay);
        timeoutIds.current.push(timeoutId);
      };

      objectSpansArray.forEach((word, index) => {
        toggleColorClass(word, index * delayIncrement);
      });
    }
  }

  const resetHighlighting = () => {
    timeoutIds.current.forEach((timeoutId) => clearTimeout(timeoutId));
    timeoutIds.current = []; // Reset the array

    // Reset highlighting styles
    const objectSpans = document.getElementsByClassName(
      'object-repeat-word'
    ) as HTMLCollectionOf<HTMLElement>;

    Array.from(objectSpans).forEach((element) => {
      element.style.color = '';
      element.style.opacity = '';
      element.classList.remove('color-me');
    });
  };

  // Use onresize, it's faster than addEventListener
  window.onresize = setWords;

  return (
    <div className='obj-repeat-container'>
      {wordDimensions && rowsArray && rowsArray.map((row, index) => {
        const alignmentClass = index % 2 !== 0 ? 'align-right' : '';
        return <p className={`object-repeat-line ${alignmentClass}`} key={index}>
          {row.map((word, index) => {
            return <span className='object-repeat-word' key={index}>{word}</span>
          })}
        </p>
      })}
    </div>
  );
};

export default ObjRepeat