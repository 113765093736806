import { MessageType } from "../types/componentTypes";
import mixes from "../assets/data/mixes.json";
import videos from "../assets/data/videos.json";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

if (!endpoint) {
  throw new Error('REACT_APP_API_ENDPOINT environment variable is not defined');
}

let baseUrl: string = endpoint;

async function getMixes() {
  // const response = await fetch(`${baseUrl}/mixes`);
  // return response.json();
  return mixes;
}

const getVideos = async () => {
  // const response = await fetch(`${baseUrl}/videos`);
  // return response.json();
  return videos;
}

const postMessage = async (message: MessageType) => {
  const response = await fetch (`${baseUrl}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
  return response;
}

export { getMixes, getVideos, postMessage };