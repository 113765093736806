import React from "react";
import "./Nav.scss";
import NavIcon from "./NavIcon";

const Nav = () => {

  const navLinks = [
    { name: 'Mixes', padding: true },
    { name: 'Videos', padding: false },
    { name: 'About', padding: true },
    { name: 'Photos', padding: true },
    { name: 'Contact', padding: false },
  ];

  return (
    <nav className="nav">
      {navLinks.map((link, index) => {
        const scrollTo = () => {
          const clickedElement = document.getElementById(link.name.toLowerCase());
          if (clickedElement) {
            clickedElement.scrollIntoView({behavior: 'smooth'});
            if (link.padding) clickedElement.style.paddingTop = `${window.innerHeight}px`;
            setTimeout(() => {
              clickedElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }, 1000);
          }
        };
        return <NavIcon key={index} title={link.name} onClick={scrollTo} />
      })}
    </nav>
  );
}

export default Nav;