import React, { useRef } from 'react';
import Nav from './components/Nav/Nav';
import Mixes from './pages/Mixes/Mixes';
import Videos from './pages/Videos/Videos';
import About from './pages/About/About';
import Photos from './pages/Photos/Photos';
import Contact from './pages/Contact/Contact';
import './styles/main.scss';

const App: React.FC = () => {


  const AppContainer = useRef<HTMLDivElement | null>(null);

  return (
    <div id='App' ref={AppContainer}>
      <Nav />
      <Mixes AppContainer={AppContainer} />
      <Videos />
      <About AppContainer={AppContainer} />
      <Photos />
      <Contact />
    </div>
  );
}

export default App;
